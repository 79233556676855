import { DataServiceClass } from 'src/app/service/DataService.service';
import { Router, NavigationError, NavigationEnd } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService
} from "@nebular/theme";

import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil, filter } from "rxjs/operators";
import { Subject } from "rxjs";
import { AuthServiceService } from 'src/app/authentication/service/auth-service.service';

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html"
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  public showUrl: boolean = false;
  public showLogout: boolean = false;
  public route: string;
  currentTheme = "default";
  constructor(
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    public dataServiceClass: DataServiceClass,
    public auth : AuthServiceService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.route = event.url;
        this.showRoute(this.route);
      }
    });
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe(themeName => {
        this.currentTheme = themeName;
      });

    this.menuService
      .onItemClick()
      .pipe(filter(({ tag }) => tag === "headerMenu"))
      .subscribe(title => {
        this[title.item["fun"]]();
      });
  }

  showRoute(route: any) {
    if( 
      route == '/exam/exam-setter-edit' ||
      route == '/exam/exam-setter-create' ||
      route == '/exam/exam-questions-add' ||
      route == '/exam/exam-questions-edit' || 
      route == '/exam/exam-questions'
    ) {
      this.showLogout = false;
    }
    else {
      this.showLogout = true
    }
    if (
      route == "/exam/home" ||
      route == "/exam/participants" || 
      route == "/exam/results" ||
      route == "/exam/rank"
    ) {
      // this.dataServiceClass._quizDataSet = 'Exam Setter';
      this.showUrl = false;
    } else {
      this.showUrl = true;
    }
  }

  logOut() {
    //this.authService.logout();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  menuClick(e) {
    console.log(e);
  }

  navigateHome() {
    this.menuService.navigateHome("dashboard");
    return false;
  }

  onClick() {
    switch (this.route) {
      case '/exam/home':
        this.router.navigate(['/exam/exam-setter-create']);
        break;
      case '/exam/exam-setter-create':
      case '/exam/exam-setter-edit':
        this.dataServiceClass.headerTextSet = 'Exam Setter';
        this.router.navigate(['/exam/home']);
        break;
      case '/exam/exam-questions':
        this.dataServiceClass.headerTextSet = 'Exam Setter';
        this.router.navigate(['/exam/home']);
        break;
      case '/exam/exam-questions-edit':
      case '/exam/exam-questions-add':
        this.router.navigate(['/exam/exam-questions']);
        break;
      case '/exam/participants/listofexams' :
        this.dataServiceClass.headerTextSet = 'Participants';
        this.router.navigate(['/exam/participants']);
        break;
      case '/exam/participants/answer-summary' :
        this.dataServiceClass.headerTextSet = this.dataServiceClass.participationDataGet.fullname != null ? this.dataServiceClass.participationDataGet.fullname : '';
        this.router.navigate(['/exam/participants/listofexams']);
        break;
      case '/exam/rank/listofexams' :
        this.dataServiceClass.headerTextSet = 'Rank';
        this.router.navigate(['/exam/rank']);
        break;
      case '/exam/rank/answer-summary' :
        this.dataServiceClass.headerTextSet = this.dataServiceClass.participationExamDetailsSet.title != null ? this.dataServiceClass.participationExamDetailsSet.title : '';
        this.router.navigate(['/exam/rank/listofexams']);
        break;
    }
  }

  onEdit() {
    this.router.navigate(['/exam/exam-questions-add']);
  }
}
