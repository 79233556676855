<div class="header-container" style="width: 100%;">
    <div class="row" style="width: 100%;margin:0px;">
        <!-- <div class="col-1 pl-0 d-flex justify-content-left">
            <nb-actions size="medium">
                <nb-action class="control-item pl-0" icon="arrow-ios-back-outline"></nb-action>
            </nb-actions>
            <img src="assets/images/arrow-ios-back-outline.svg" (click)="backToTournament()" width="30" class="mb-0" > 
        </div> -->
        <div class="col-3 pl-0 d-flex justify-content-left align-items-center">
            <nb-actions size="small">
                <nb-action class="control-item pl-0" icon="arrow-ios-back-outline" (click)="onClick()" *ngIf="showUrl"></nb-action>
            </nb-actions>
        </div>
        <div class="col-6 d-flex justify-content-center align-items-center pr-0 pl-0 d-flex">
            <h6 class="mb-0">{{dataServiceClass.headerTextGet}}</h6>
        </div>
        <div class="col-3 d-flex justify-content-end align-items-center pl-0 pr-0 d-flex justify-content-end">
            <!-- <nb-actions size="small">
                <nb-action class="control-item pr-0" icon="funnel-outline"></nb-action>
            </nb-actions> -->
            <i class="fad fa-plus" style="font-size: 26px;" (click)="onEdit()" *ngIf="(route == '/exam/exam-questions' &&
            dataServiceClass._quizDataGet.question_set != dataServiceClass._quizDataGet.total_question) ? true : false"></i>
            <i class="fal fa-layer-plus" (click)="onClick()" *ngIf="route == '/exam/home'" style="font-size: 26px;"></i>
            <i class="fal fa-sign-out-alt ml-4" (click)="auth.logout()" style="font-size: 26px;" *ngIf="showLogout"></i>
            <!-- <button nbButton hero outline status="primary" size="small" (click)="onEdit()" *ngIf="(route == '/exam/exam-questions' &&
            dataServiceClass._quizDataGet.question_set != dataServiceClass._quizDataGet.total_question) ? true : false">Add</button> -->
            <!--<button nbButton hero outline status="primary" size="small" (click)="onClick()" *ngIf="route == '/exam/home'">Create</button>-->
        </div>
    </div>
    <!-- <nb-actions size="small">`
        <nb-action class="control-item">
            <nb-search type="rotate-layout"></nb-search>
        </nb-action>
        <nb-action class="control-item" icon="email-outline"></nb-action>
        <nb-action class="control-item" icon="bell-outline"></nb-action>
        *nbIsGranted="['view', 'user']"
        <nb-action class="user-action">
            <nb-user nbContextMenuTag="headerMenu" [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.name" [picture]="user?.picture" (itemClick)="logOut()">
            </nb-user>
        </nb-action>
    </nb-actions> -->
</div>