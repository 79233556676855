import { Router, NavigationEnd } from '@angular/router';
import { Component } from '@angular/core';
import { AuthServiceService } from 'src/app/authentication/service/auth-service.service';
import { DataServiceClass } from './../../../service/DataService.service';
@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl : './footer.component.html',
})
export class FooterComponent {
  constructor(private dataServiceClass : DataServiceClass) {}

  setHeaderData(data) {
    switch(data) {
      case 'home' : 
      this.dataServiceClass.headerTextSet = 'Home'
      break;
      case 'participants' : 
      this.dataServiceClass.headerTextSet = 'Participants'
      break;
      case 'results' : 
      this.dataServiceClass.headerTextSet = 'Results'
      break;
      case 'rank' : 
      this.dataServiceClass.headerTextSet = 'Ranks'
      break;
      case 'report' : 
      this.dataServiceClass.headerTextSet = 'Report'
      break;
    }
  }
}
