import { environment } from 'src/environments/environment';
export default class Url {
   static imagePath: string = `${'http://staging.procric8.com/quiz/service/'}`;
   //Auth Api
   static Login_URL: string = `${environment.Api_Base_Url + `${'v1/login'}`}`;
   // User Api
   static Verified_User_URL: string = `${environment.Api_Base_Url + `${'v1/user/verified/list'}`}`;
   static Unverified_User_URL: string = `${environment.Api_Base_Url + `${'v1/user/unverified/list'}`}`;
   static Save_Verified_User: string = `${environment.Api_Base_Url + `${'v1/user/verified'}`}`;
   //Quiz Api
   static Quiz_List_Get_URL: string = `${environment.Api_Base_Url + `${'v1/quiz/list'}`}`;
   static Quiz_Get_BYID_URL: string = `${environment.Api_Base_Url + `${'v1/quiz/{id}'}`}`;
   static Quiz_Create_Post_URL: string = `${environment.Api_Base_Url + `${'v1/quiz'}`}`;
   static Quiz_Update_Put_URL: string = `${environment.Api_Base_Url + `${'v1/quiz/{id}'}`}`;
   static Quiz_Publih_URL: string = `${environment.Api_Base_Url + `${'v1/quiz/{id}/publish'}`}`;
   static Quiz_Unpublish_URL: string = `${environment.Api_Base_Url + `${'v1/quiz/{id}/unpublish'}`}`;
   static Quiz_Delete_URL: string = `${environment.Api_Base_Url + `${'v1/quiz/{id}'}`}`;
   //Quiz Questions
   static Quiz_Question_List_Get_URL: string = `${environment.Api_Base_Url + `${'v1/quiz/{id}/question/list'}`}`;
   static Quiz_Question_Get_URL: string = `${environment.Api_Base_Url + `${'v1/quiz/{id}/question/{q_id}'}`}`;
   static Quiz_Question_Add_Post_URL: string = `${environment.Api_Base_Url + `${'v1/quiz/{id}/question'}`}`;
   static Quiz_Question_Edit_Put_URL: string = `${environment.Api_Base_Url + `${'v1/quiz/{id}/question/{q_id}'}`}`;
   static Quiz_Question_Delete_URL: string = `${environment.Api_Base_Url + `${'v1/quiz/{id}/question/{q_id}'}`}`;
   //Reports
   static Report_Participants_List: string = `${environment.Api_Base_Url + `${'v1/report/participants/list'}`}`;
   static Report_Participants_Exam_Details_List: string = `${environment.Api_Base_Url + `${'v1/report/participants/{userId}/attempts'}`}`;
   static Report_Participants_Exam_Summary_List: string = `${environment.Api_Base_Url + `${'v1/report/participants/{userId}/attempts/{attempt_id}'}`}`;
   static Report_Participants_Result_List: string = `${environment.Api_Base_Url + `${'v1/report/participants/attempts'}`}`;
   static Report_Exam_Summary_List: string = `${environment.Api_Base_Url + `${'v1/report/quizs/summary'}`}`;
   static Report_Exam_Participants_Summary_List: string = `${environment.Api_Base_Url + `${'v1/report/quizs/{q_id}/summary'}`}`;
   static Report_Unverifield_User_List: string = `${environment.Api_Base_Url + `${'v1/user/unverified/list'}`}`;
   static Report_OTP_List: string = `${environment.Api_Base_Url + `${'v1/user/otps/list'}`}`;
   static Report_OTP_Active_Post : string = `${environment.Api_Base_Url + `${'v1/user/otp/{number}/activate'}`}`;
   //Teams Api
   static Team_List_Get : string = `${environment.Api_Base_Url + `${'v1/team/list'}`}`;
}