import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor , HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthServiceService } from '../authentication/service/auth-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { map } from 'rxjs/operators';
@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthServiceService,private ngxUiLoaderService: NgxUiLoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with basic auth credentials if available
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.result.token != null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.result.token}`
                }
            });
        }
        this.ngxUiLoaderService.startLoader('master', 'fg-default');
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.ngxUiLoaderService.stopLoader('master', 'fg-default');
                    if (event.body && event.body.success) {
                        // this.toastr.successToastr(event.body.success,event.body.message);
                    }
                }
                return event;
            }));
    }
}