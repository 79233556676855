import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
@Injectable()
export class DataServiceClass {
  private _currentUser: any = {};
  private _quizData: any = null;
  private _questionData: any = null;
  private _participationData: any = null;
  private _participationExamDetails: any = null;
  private _headerText: string = 'Exam Setter';
  private onDestroy$ = new Subject<boolean>();
  constructor() {
    this.userDetailObj();
    this.quizDataObj();
    this.questionDataObj();
    this.participationDataObj();
    this.participationExamObj();
    this.headerText();
  }
  //Header Text 
  public get headerTextGet(): string {
    return this._headerText;
  }
  public set headerTextSet(value: string) {
    this._headerText = value;
    this.setLocal("Quiz_Admin", "header", this._headerText);
  }
  //Participation Data
  public get participationDataGet(): any {
    return this._participationData;
  }
  public set participationDataSet(value: any) {
    this._participationData = value;
    this.setLocal("Quiz_Admin", "participation", this._participationData);
  }
  //Participation Exam Details
  public get participationExamDetailszGet(): any {
    return this._participationExamDetails;
  }
  public set participationExamDetailsSet(value: any) {
    this._participationExamDetails = value;
    this.setLocal("Quiz_Admin", "participationExamDetails", this._participationExamDetails);
  }
  //Question Data
  public get questionDataGet(): any {
    return this._questionData;
  }
  public set questionDataSet(value: any) {
    this._questionData = value;
    this.setLocal("Quiz_Admin", "question", this._questionData);
  }
  //Quiz Data
  public get _quizDataGet(): any {
    return this._quizData;
  }
  public set _quizDataSet(value: any) {
    this._quizData = value;
    this.setLocal("Quiz_Admin", "quiz", this._quizData);
  }
  //currentUser Data
  public get currentUserGet(): any {
    return this._currentUser;
  }
  public set currentUserSet(value: any) {
    this._currentUser = value;
    this.setLocal("Quiz_Admin", "User", this._currentUser);
  }
  //Init User data
  private headerText() {
    let header = this.getLocal('Quiz_Admin', 'header');
    if (header != undefined) {
      this._headerText = header;
    } else {
      this._headerText = null;
    }
  }
  //Init User data
  private userDetailObj() {
    let userDetails = this.getLocal('Quiz_Admin', 'User');
    if (userDetails != undefined) {
      this._currentUser = userDetails;
    } else {
      this._currentUser = null;
    }
  }
  //Init Quiz data
  private quizDataObj() {
    let quizDetails = this.getLocal('Quiz_Admin', 'quiz');
    if (quizDetails != undefined) {
      this._quizData = quizDetails;
    } else {
      this._quizData = null;
    }
  }
  //Init Question data
  private questionDataObj() {
    let questionDetails = this.getLocal('Quiz_Admin', 'question');
    if (questionDetails != undefined) {
      this._questionData = questionDetails;
    } else {
      this._questionData = null;
    }
  }
  //Init Participation data
  private participationDataObj() {
    let participation = this.getLocal('Quiz_Admin', 'participation');
    if (participation != undefined) {
      this._participationData = participation;
    } else {
      this._participationData = null;
    }
  }
  //Init Participation Exam details
  private participationExamObj() {
    let participationExamDetail = this.getLocal('Quiz_Admin', 'participationExamDetails');
    if (participationExamDetail != undefined) {
      this._participationExamDetails = participationExamDetail;
    } else {
      this._participationExamDetails = null;
    }
  }
  //clear DataService
  clearDataService() {
    this._currentUser = null;
    this._quizData = null;
    this._questionData = null;
    this._participationData = null;
    this._participationExamDetails = null;
    this._headerText = null;
  }
  //Set module to localstorage
  setLocal(module: string, key: string, data: any) {
    // this.removeLocal(module , key);
    let modObj = JSON.parse(localStorage.getItem(module));
    if (modObj == undefined) {
      modObj = {};
    }
    modObj[key] = data;
    localStorage.setItem(module, JSON.stringify(modObj));
  }
  //Get module from localstorage
  getLocal(module: string, key: string) {
    let modObj = JSON.parse(localStorage.getItem(module));
    if (modObj == undefined) {
      modObj = {};
      localStorage.setItem(module, JSON.stringify(modObj));
    }
    if (key != undefined) return modObj[key];
    return modObj;
  }
  //Remove module from localstorage
  removeLocal(module: string, key: string) {
    if (key != undefined) {
      let modObj = JSON.parse(localStorage.getItem(module));
      if (modObj != undefined) {
        delete modObj[key];
        localStorage.setItem(module, JSON.stringify(modObj));
        return;
      }
    }
    localStorage.removeItem(module);
  }
  //Filter Object 
  public filter(reqObj) {
    Object.keys(reqObj).forEach(function (key) {
      if (reqObj[key] == null || reqObj[key] == "" || reqObj[key] == "string" || reqObj[key] == "undefined") {
        delete reqObj[key];
      }
    });
    return reqObj;
  }
  //Whatsapp Share
  public shareOnWhatsApp(message: string, mobile: any) {
    if (message == undefined || message == "") return;
    if (mobile == undefined) {
      window.location.href = "whatsapp://send?text=" + encodeURIComponent(message);
    } else {
      window.location.href = "whatsapp://send?phone=91" + mobile + "&text=" + encodeURIComponent(message);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
