import { DataServiceClass } from 'src/app/service/DataService.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../../service/http.service'
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import Url from 'src/app/_helpers/Url';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(
    private http: HttpService,
    private router: Router,
    private dataServiceClass: DataServiceClass
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    return this.currentUserSubject.value;
  }

  login(data) {
    return this.http.postData(data, `${Url.Login_URL}`)
      .pipe(map(user => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.dataServiceClass.currentUserSet = user;
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.dataServiceClass.clearDataService();
    this.router.navigate(['']);
  }

}
