import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../service/alert.service';
import { AuthServiceService } from '../authentication/service/auth-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthServiceService, private alert: AlertService,private ngxUiLoaderService: NgxUiLoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(err);
            this.ngxUiLoaderService.stopLoader('master', 'fg-default');
            if (err.status === 403) {
                this.alert.showToast('danger', 'Validation Error', err.message);
                this.authenticationService.logout();
            }
            if (err.status === 404) {
                this.alert.showToast('danger', err.error.message)
            } if (err.status == 401) {
                this.alert.showToast('danger', err.error.message);
                this.authenticationService.logout();
            } else if (err.status == 0) {
                this.alert.showToast('danger', "Failed", "Internal Server Error");
            } else if (err.status == 500 && err.statusText == "Internal Server Error") {
                this.alert.showToast('danger', "Failed", "Internal Server Error");
            } else if (err.status == 400) {
                if (typeof err.error.error === "string") {
                    this.alert.showToast('danger', "Failed", (err.error.message));
                } else {
                    // console.log(Object.values(err.error.error)[0][0]);
                    this.alert.showToast('danger', "Failed", (Object.values(err.error.error)[0][0]));
                }
            }
            const error = err.error.message || err.statusText;
            return throwError(err);
        }))
    }
}