<nav class="navbar fixed-bottom">
    <ul class="navbar-nav w-100">
        <li class="nav-item">
            <a class="nav-link" (click)="setHeaderData('report')" [routerLink]="['/exam/report']" routerLinkActive="router-link-active"><i class="fal fa-users"></i>Participants</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="setHeaderData('task')" routerLink="/exam/task" routerLinkActive="router-link-active"><i class="fal fa-task"></i>Task</a>
        </li>
        <!-- <li class="nav-item">
            <a class="nav-link" (click)="setHeaderData('rank')" routerLink="/exam/rank" routerLinkActive="router-link-active"><i class="fal fa-trophy"></i>Ranks</a>
        </li> -->
        <li class="nav-item">
            <a class="nav-link" (click)="setHeaderData('report')" [routerLink]="['/exam/report']" routerLinkActive="router-link-active"><i class="fal fa-file"></i>Reports</a>
        </li>
        <!-- <div class="dropdown">
            <button class="btn dropdown-toggle my-button" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fal fa-ellipsis-h"></i>
            </button>
            <div class="dropdown-menu custom-dropdown-menu-logout" aria-labelledby="dropdownMenuButton">
                <div class="more-menu-caret">
                    <div class="more-menu-caret-outer"></div>
                    <div class="more-menu-caret-inner"></div>
                </div>
                <a class="dropdown-item logout-link" (click)="auth.logout()">Logout</a>
            </div>
        </div> -->
    </ul>
</nav>